import { render, staticRenderFns } from "./Overlay.vue?vue&type=template&id=b8af7fee&"
import script from "./Overlay.vue?vue&type=script&lang=js&"
export * from "./Overlay.vue?vue&type=script&lang=js&"
import style0 from "./Overlay.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/CAP/bamboo/agent6/xml-data/build-dir/MAIN-GC-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8af7fee')) {
      api.createRecord('b8af7fee', component.options)
    } else {
      api.reload('b8af7fee', component.options)
    }
    module.hot.accept("./Overlay.vue?vue&type=template&id=b8af7fee&", function () {
      api.rerender('b8af7fee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/chat/Overlay.vue"
export default component.exports