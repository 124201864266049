var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _vm._m(0),
    _vm._v(" "),
    _c("td", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.package,
              expression: "package"
            }
          ],
          staticClass: "cx-input cx-form-control",
          class: { "cx-error": _vm.v.$error },
          attrs: { id: "package" },
          on: {
            input: function($event) {
              return _vm.v.$touch()
            },
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.package = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { value: "", disabled: "", selected: "" } }, [
            _vm._v("Select package...")
          ]),
          _vm._v(" "),
          _vm._l(_vm.packages, function(key) {
            return _c("option", { domProps: { value: key } }, [
              _vm._v("\n        " + _vm._s(key) + "\n      ")
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c(
        "label",
        { staticClass: "cx-control-label", attrs: { for: "package" } },
        [_vm._v("Hosting Package")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }