var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cx-form-wrapper", staticStyle: { display: "block" } },
    [
      _c(
        "div",
        { staticClass: "cx-form cx-form-horizontal", attrs: { role: "form" } },
        [
          _c("div", { staticClass: "cx-form-inputs" }, [
            _c(
              "table",
              {
                staticStyle: {
                  "border-collapse": "separate",
                  "border-spacing": "20px",
                  "table-layout": "auto",
                  width: "100%"
                },
                attrs: { role: "none" }
              },
              [
                _c("chat-alerts", {
                  attrs: {
                    display: _vm.display,
                    "is-customer": this.isCustomer
                  }
                }),
                _vm._v(" "),
                _vm.error
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "cx-warning",
                          staticStyle: { "text-align": "center" },
                          attrs: { colspan: "2" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.error) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("existing-customer", {
                  attrs: { v: _vm.$v.isCustomer },
                  on: { input: _vm.updateCustomer },
                  model: {
                    value: _vm.isCustomer,
                    callback: function($$v) {
                      _vm.isCustomer = $$v
                    },
                    expression: "isCustomer"
                  }
                }),
                _vm._v(" "),
                !_vm.isCustomer || _vm.authenticationService.type !== "OKTA"
                  ? [
                      _c("text-input", {
                        attrs: {
                          v: _vm.$v.firstname,
                          id: "firstname",
                          "auto-complete": "given-name",
                          placeholder: "John",
                          label: "First Name",
                          name: "firstname"
                        },
                        on: { submit: _vm.next },
                        model: {
                          value: _vm.firstname,
                          callback: function($$v) {
                            _vm.firstname = $$v
                          },
                          expression: "firstname"
                        }
                      }),
                      _vm._v(" "),
                      _c("text-input", {
                        attrs: {
                          v: _vm.$v.lastname,
                          id: "lastname",
                          "auto-complete": "family-name",
                          placeholder: "Doe",
                          label: "Last Name",
                          name: "lastname"
                        },
                        on: { submit: _vm.next },
                        model: {
                          value: _vm.lastname,
                          callback: function($$v) {
                            _vm.lastname = $$v
                          },
                          expression: "lastname"
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.authenticationService.type === "DEFAULT" || !_vm.isCustomer
                  ? [
                      _c("identifier", {
                        attrs: {
                          "phone-enabled": _vm.isCustomer,
                          v: _vm.$v.identifier
                        },
                        model: {
                          value: _vm.identifier,
                          callback: function($$v) {
                            _vm.identifier = $$v
                          },
                          expression: "identifier"
                        }
                      }),
                      _vm._v(" "),
                      _vm.identifier === "domain"
                        ? _c("text-input", {
                            attrs: {
                              v: _vm.$v.domain,
                              id: "domain",
                              "auto-complete": "url",
                              placeholder: "example.com",
                              label: "",
                              name: "domain"
                            },
                            on: { submit: _vm.next },
                            model: {
                              value: _vm.domain,
                              callback: function($$v) {
                                _vm.domain = $$v
                              },
                              expression: "domain"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.identifier === "phone"
                        ? _c("text-input", {
                            attrs: {
                              v: _vm.$v.phone,
                              id: "phone",
                              "auto-complete": "tel",
                              placeholder: "1234567890",
                              label: "",
                              name: "phone"
                            },
                            on: { submit: _vm.next },
                            model: {
                              value: _vm.phone,
                              callback: function($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.identifier === "email"
                        ? _c("text-input", {
                            attrs: {
                              v: _vm.$v.email,
                              id: "email",
                              "auto-complete": "email",
                              placeholder: "john.doe@example.com",
                              label: "",
                              name: "email"
                            },
                            on: { submit: _vm.next },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.isCustomer
                  ? [
                      _vm.brand.getHostingPackages().length > 0
                        ? _c("hosting-package", {
                            attrs: {
                              v: _vm.$v.package,
                              packages: _vm.brand.getHostingPackages()
                            },
                            model: {
                              value: _vm.package,
                              callback: function($$v) {
                                _vm.package = $$v
                              },
                              expression: "package"
                            }
                          })
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.authenticationService.type === "HOSTGATOR" && _vm.isCustomer
                  ? [
                      _c("text-input", {
                        attrs: {
                          v: _vm.$v.email,
                          id: "email",
                          "auto-complete": "email",
                          placeholder: "Email Address",
                          label: "Email",
                          name: "email"
                        },
                        on: { submit: _vm.next },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _vm._v(" "),
                      _c("text-input", {
                        attrs: {
                          v: _vm.$v.password,
                          id: "password",
                          type: "password",
                          "auto-complete": "password",
                          label: "Password",
                          placeholder: "********",
                          name: "password"
                        },
                        on: { submit: _vm.next },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { staticClass: "cx-error", attrs: { colspan: "2" } },
                          [
                            _c("vue-recaptcha", {
                              ref: "recaptcha",
                              staticStyle: { float: "right" },
                              attrs: {
                                sitekey: _vm.recaptchaKey,
                                loadRecaptchaScript: true
                              },
                              on: { verify: _vm.verify }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm.isCustomer && _vm.authenticationService.type === "OKTA"
            ? [
                _c("okta-login", {
                  on: {
                    authenticated: function($event) {
                      return _vm.next(true)
                    }
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "cx-button-group cx-buttons-binary" }, [
            _c(
              "button",
              {
                staticClass: "cx-btn cx-btn-default",
                attrs: { "aria-label": "Cancel" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close-overlay")
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            !_vm.isCustomer || _vm.authenticationService.type === "DEFAULT"
              ? _c(
                  "button",
                  {
                    staticClass: "cx-submit cx-btn cx-btn-primary",
                    attrs: { id: "genesys-next", "aria-label": "Next" },
                    on: {
                      click: function($event) {
                        return _vm.next(false)
                      }
                    }
                  },
                  [_vm._v("Next\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isCustomer && _vm.authenticationService.type === "HOSTGATOR"
              ? _c(
                  "button",
                  {
                    staticClass: "cx-submit cx-btn cx-btn-primary",
                    attrs: { id: "genesys-submit", "aria-label": "Next" },
                    on: {
                      click: function($event) {
                        return _vm.next(false)
                      }
                    }
                  },
                  [_vm._v("Next\n      ")]
                )
              : _vm._e()
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }