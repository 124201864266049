var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    _vm._l(_vm.articles, function(article) {
      return _c("tr", [
        _c("td", { attrs: { colspan: "2" } }, [
          _c("label", [
            _c("a", { attrs: { target: "_blank", href: article.url } }, [
              _vm._v(_vm._s(article.title))
            ])
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(article.summary))])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }