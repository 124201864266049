var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cx-footer" }, [
    _c("small", [
      _vm._v(
        "\n    By filling out this form, you agree to " +
          _vm._s(this.brand.getName()) +
          " "
      ),
      _c(
        "a",
        {
          attrs: { href: this.brand.getTermsOfServiceLink(), target: "_blank" }
        },
        [_vm._v("terms of service")]
      ),
      _vm._v(
        "\n    and acknowledge receipt of " + _vm._s(this.brand.getName()) + " "
      ),
      _c(
        "a",
        {
          attrs: { href: this.brand.getPrivacyPolicyLink(), target: "_blank" }
        },
        [_vm._v("privacy\n    notice")]
      ),
      _vm._v(".\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }