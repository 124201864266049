var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    _vm._l(_vm.alerts, function(alert) {
      return _c("tr", [
        _c(
          "td",
          {
            staticClass: "cx-warning",
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "2" }
          },
          [
            _c("strong", [_vm._v(_vm._s(alert.title))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(alert.content))])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }