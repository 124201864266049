var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cx-form-wrapper",
      staticStyle: { "overflow-y": "auto", "max-width": "600px" }
    },
    [
      _c(
        "div",
        { staticClass: "cx-form cx-form-horizontal", attrs: { role: "form" } },
        [
          _c("div", { staticClass: "cx-form-inputs" }, [
            _c(
              "table",
              {
                staticStyle: {
                  "border-collapse": "separate",
                  "border-spacing": "20px",
                  "table-layout": "auto",
                  width: "100%"
                },
                attrs: { role: "none" }
              },
              [
                _c("chat-alerts", {
                  attrs: {
                    display: _vm.display,
                    "is-customer": this.userDataStore.isCustomer
                  }
                }),
                _vm._v(" "),
                !_vm.emergency
                  ? _c("tr", [
                      _c("td", [
                        _vm._v("The current estimated waiting time is")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n            " + _vm._s(_vm.ewt) + "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.userDataStore.isCustomer
                  ? _c("knowledge-base-articles")
                  : _vm._e(),
                _vm._v(" "),
                !_vm.emergency
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "2" } }, [
                        _vm._v("Would you like to start a chat with an agent?")
                      ])
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "cx-button-group cx-buttons-binary",
              staticStyle: { "padding-bottom": "50px" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "cx-btn cx-btn-default",
                  attrs: { "aria-label": "Cancel" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-overlay")
                    }
                  }
                },
                [_vm._v("Cancel\n      ")]
              ),
              _vm._v(" "),
              !_vm.emergency
                ? _c(
                    "button",
                    {
                      staticClass: "cx-submit cx-btn cx-btn-primary",
                      attrs: { "aria-label": "Next" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("Start Chat\n      ")]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }