var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("th", [
      _c("label", { staticClass: "cx-control-label", attrs: { for: _vm.id } }, [
        _vm._v(_vm._s(_vm.label))
      ])
    ]),
    _vm._v(" "),
    _c("td", [
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            staticClass: "cx-input cx-form-control",
            class: { "cx-error": _vm.v.$error },
            attrs: {
              id: _vm.id,
              name: _vm.name,
              autocomplete: _vm.autoComplete,
              maxlength: "255",
              placeholder: _vm.placeholder,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, null) > -1
                : _vm.model
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("submit")
              },
              input: function($event) {
                return _vm.v.$touch()
              },
              change: function($event) {
                var $$a = _vm.model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.model = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.model = $$c
                }
              }
            }
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            staticClass: "cx-input cx-form-control",
            class: { "cx-error": _vm.v.$error },
            attrs: {
              id: _vm.id,
              name: _vm.name,
              autocomplete: _vm.autoComplete,
              maxlength: "255",
              placeholder: _vm.placeholder,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.model, null) },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("submit")
              },
              input: function($event) {
                return _vm.v.$touch()
              },
              change: function($event) {
                _vm.model = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            staticClass: "cx-input cx-form-control",
            class: { "cx-error": _vm.v.$error },
            attrs: {
              id: _vm.id,
              name: _vm.name,
              autocomplete: _vm.autoComplete,
              maxlength: "255",
              placeholder: _vm.placeholder,
              type: _vm.type
            },
            domProps: { value: _vm.model },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("submit")
              },
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.model = $event.target.value
                },
                function($event) {
                  return _vm.v.$touch()
                }
              ]
            }
          })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }