var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("th"),
    _vm._v(" "),
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isCustomer,
            expression: "isCustomer"
          }
        ],
        attrs: { type: "radio", id: "existing", name: "customer" },
        domProps: { value: true, checked: _vm._q(_vm.isCustomer, true) },
        on: {
          input: function($event) {
            return _vm.v.$touch()
          },
          change: function($event) {
            _vm.isCustomer = true
          }
        }
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "existing" } }, [
        _vm._v("Existing Customer")
      ]),
      _vm._v("\n     \n    "),
      _c("br", { staticClass: "cx-small-radios" }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isCustomer,
            expression: "isCustomer"
          }
        ],
        attrs: { type: "radio", id: "new", name: "customer" },
        domProps: { value: false, checked: _vm._q(_vm.isCustomer, false) },
        on: {
          input: function($event) {
            return _vm.v.$touch()
          },
          change: function($event) {
            _vm.isCustomer = false
          }
        }
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "new" } }, [_vm._v("New Customer")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }