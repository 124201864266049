var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cx-widget cx-common-container cx-overlay cx-close",
      class: { "cx-desktop": _vm.desktop },
      attrs: {
        role: "dialog",
        "aria-label": "Get Help Window",
        "aria-modal": "true",
        "aria-labelledby": "title",
        "aria-describedby": "title"
      }
    },
    [
      _c("div", { staticClass: "cx-titlebar" }, [
        _c("h2", { staticClass: "cx-title", attrs: { id: "title" } }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cx-subtitle" }, [
          _vm._v(
            "In order to assist you better, please answer the following questions"
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cx-body" }, [
        _c(
          "div",
          { staticClass: "cx-content", staticStyle: { "max-height": "50vh" } },
          [
            _c(_vm.step, {
              tag: "component",
              on: {
                "close-overlay": _vm.close,
                "change-step": _vm.changeStep,
                "show-alert": _vm.showAlert
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("overlay-window-controls"),
      _vm._v(" "),
      _c("overlay-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }