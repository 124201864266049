var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cx-button-group cx-buttons-window-control" },
    [
      _c("button", {
        staticClass: "cx-icon cx-button-close",
        attrs: { "aria-label": "Close", "data-icon": "close" },
        on: { click: _vm.close }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }