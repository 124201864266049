var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "cx-widget cx-common-container cx-overlay cx-widget cx-close",
      class: { "cx-desktop": _vm.desktop },
      attrs: {
        role: "dialog",
        "aria-label": "Get Help Window",
        "aria-modal": "true",
        "aria-labelledby": "title",
        "aria-describedby": "title"
      }
    },
    [
      _c("div", { staticClass: "cx-titlebar" }, [
        _c("h2", { staticClass: "cx-title", attrs: { id: "title" } }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cx-subtitle" }, [
          _vm._v(
            "In order to assist you better, please login using your " +
              _vm._s(_vm.brand.getName()) +
              " account"
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cx-body" }, [
        _c(
          "div",
          { staticClass: "cx-content" },
          [
            _c("okta-login", { on: { authenticated: _vm.authenticated } }),
            _vm._v(" "),
            _c("div", { staticClass: "cx-button-group cx-buttons-binary" }, [
              _c(
                "button",
                {
                  staticClass: "cx-btn cx-btn-default",
                  attrs: {
                    "aria-label": "Cancel",
                    id: "credential-overlay-close"
                  },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("overlay-window-controls"),
      _vm._v(" "),
      _c("overlay-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }