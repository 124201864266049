var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cx-form-wrapper", staticStyle: { display: "block" } },
    [
      _c(
        "div",
        { staticClass: "cx-form cx-form-horizontal", attrs: { role: "form" } },
        [
          _c("div", { staticClass: "cx-form-inputs" }, [
            _c(
              "table",
              {
                staticStyle: {
                  "border-collapse": "separate",
                  "border-spacing": "20px",
                  "table-layout": "auto",
                  width: "100%"
                },
                attrs: { role: "none" }
              },
              [
                _c("chat-alerts", {
                  attrs: {
                    display: _vm.display,
                    "is-customer": this.userDataStore.isCustomer
                  }
                }),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.topic.$model,
                            expression: "$v.topic.$model"
                          }
                        ],
                        staticClass: "cx-input cx-form-control",
                        class: { "cx-error": _vm.$v.topic.$error },
                        attrs: { id: "topic" },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.$v.topic,
                                "$model",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.topicChanged
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "", disabled: "", selected: "" } },
                          [_vm._v("Select a topic...")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.topics, function(topic) {
                          return _c("option", { domProps: { value: topic } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(topic.question) +
                                "\n              "
                            )
                          ])
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.option.$model,
                            expression: "$v.option.$model"
                          }
                        ],
                        staticClass: "cx-input cx-form-control",
                        class: { "cx-error": _vm.$v.option.$error },
                        attrs: { id: "description" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.$v.option,
                              "$model",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "", disabled: "", selected: "" } },
                          [_vm._v("Select a topic...")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.options, function(option) {
                          return _c("option", { domProps: { value: option } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(option.question) +
                                "\n              "
                            )
                          ])
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.subject.$model,
                          expression: "$v.subject.$model"
                        }
                      ],
                      staticClass: "cx-input cx-form-control",
                      class: { "cx-error": _vm.$v.subject.$error },
                      attrs: {
                        autocomplete: "",
                        id: "additional",
                        name: "additional",
                        maxlength: "255",
                        placeholder: "Type here...",
                        autofocus: "",
                        type: "text"
                      },
                      domProps: { value: _vm.$v.subject.$model },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.next($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.subject,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cx-button-group cx-buttons-binary" }, [
            _c(
              "button",
              {
                staticClass: "cx-btn cx-btn-default",
                attrs: { "aria-label": "Cancel" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close-overlay")
                  }
                }
              },
              [_vm._v("Cancel\n      ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "cx-submit cx-btn cx-btn-primary",
                attrs: { "aria-label": "Start Chat" },
                on: { click: _vm.next }
              },
              [_vm._v("Next\n      ")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c(
        "label",
        { staticClass: "cx-control-label i18n", attrs: { for: "topic" } },
        [_vm._v("Topic")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c(
        "label",
        { staticClass: "cx-control-label i18n", attrs: { for: "description" } },
        [_vm._v("Description")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c(
        "label",
        { staticClass: "cx-control-label i18n", attrs: { for: "additional" } },
        [_vm._v("Additional Information")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }