var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "cx-widget cx-common-container cx-overlay cx-widget cx-close",
      class: { "cx-desktop": _vm.desktop },
      attrs: {
        role: "dialog",
        "aria-label": "Get Help Window",
        "aria-modal": "true",
        "aria-labelledby": "title",
        "aria-describedby": "title"
      }
    },
    [
      _c("div", { staticClass: "cx-titlebar" }, [
        _c("h2", { staticClass: "cx-title", attrs: { id: "title" } }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cx-subtitle" }, [
          _vm._v(
            "In order to assist you better, please login using your " +
              _vm._s(_vm.brand.getName()) +
              " account\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cx-body" }, [
        _c("div", { staticClass: "cx-content" }, [
          _c(
            "div",
            {
              staticClass: "cx-form-wrapper",
              staticStyle: { display: "block" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "cx-form cx-form-horizontal",
                  attrs: { role: "form" }
                },
                [
                  _c("div", { staticClass: "cx-form-inputs" }, [
                    _c(
                      "table",
                      {
                        staticStyle: {
                          "border-collapse": "separate",
                          "border-spacing": "20px",
                          "table-layout": "auto",
                          width: "100%"
                        },
                        attrs: { role: "none" }
                      },
                      [
                        _vm.error
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "cx-warning",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.error) +
                                      "\n                "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("text-input", {
                          attrs: {
                            v: _vm.$v.email,
                            id: "email",
                            "auto-complete": "email",
                            placeholder: "Email Address",
                            label: "Email",
                            name: "email"
                          },
                          on: { submit: _vm.authenticate },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        }),
                        _vm._v(" "),
                        _c("text-input", {
                          attrs: {
                            v: _vm.$v.password,
                            id: "password",
                            type: "password",
                            "auto-complete": "password",
                            label: "Password",
                            placeholder: "********",
                            name: "password"
                          },
                          on: { submit: _vm.authenticate },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        }),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "cx-error",
                              attrs: { colspan: "2" }
                            },
                            [
                              _c("vue-recaptcha", {
                                ref: "recaptcha",
                                staticStyle: { float: "right" },
                                attrs: {
                                  sitekey: _vm.recaptchaKey,
                                  loadRecaptchaScript: true
                                },
                                on: { verify: _vm.verify }
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cx-button-group cx-buttons-binary" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "cx-btn cx-btn-default",
                          attrs: { "aria-label": "Cancel" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "cx-submit cx-btn cx-btn-primary",
                          attrs: { id: "genesys-submit", "aria-label": "Next" },
                          on: { click: _vm.authenticate }
                        },
                        [_vm._v("Login\n            ")]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("overlay-window-controls"),
      _vm._v(" "),
      _c("overlay-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }