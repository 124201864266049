var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.identifier,
              expression: "identifier"
            }
          ],
          attrs: {
            type: "radio",
            id: "id-domain",
            value: "domain",
            checked: ""
          },
          domProps: { checked: _vm._q(_vm.identifier, "domain") },
          on: {
            input: function($event) {
              return _vm.v.$touch()
            },
            change: function($event) {
              _vm.identifier = "domain"
            }
          }
        }),
        _c("label", { attrs: { for: "id-domain" } }, [_vm._v("Domain")]),
        _vm._v("\n     \n    "),
        _c("br", { staticClass: "cx-small-radios" }),
        _vm._v(" "),
        _vm.phoneEnabled
          ? _c("fragment", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.identifier,
                    expression: "identifier"
                  }
                ],
                attrs: { type: "radio", id: "id-phone", value: "phone" },
                domProps: { checked: _vm._q(_vm.identifier, "phone") },
                on: {
                  input: function($event) {
                    return _vm.v.$touch()
                  },
                  change: function($event) {
                    _vm.identifier = "phone"
                  }
                }
              }),
              _c("label", { attrs: { for: "id-phone" } }, [_vm._v("Phone")]),
              _vm._v("\n       \n      "),
              _c("br", { staticClass: "cx-small-radios" })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.identifier,
              expression: "identifier"
            }
          ],
          attrs: { type: "radio", id: "id-email", value: "email" },
          domProps: { checked: _vm._q(_vm.identifier, "email") },
          on: {
            input: function($event) {
              return _vm.v.$touch()
            },
            change: function($event) {
              _vm.identifier = "email"
            }
          }
        }),
        _c("label", { attrs: { for: "id-email" } }, [_vm._v("Email")])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c("label", { staticClass: "cx-control-label" }, [_vm._v("Identifier")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }