import { render, staticRenderFns } from "./Identifier.vue?vue&type=template&id=30435314&"
import script from "./Identifier.vue?vue&type=script&lang=js&"
export * from "./Identifier.vue?vue&type=script&lang=js&"
import style0 from "./Identifier.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/CAP/bamboo/agent6/xml-data/build-dir/MAIN-GC-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30435314')) {
      api.createRecord('30435314', component.options)
    } else {
      api.reload('30435314', component.options)
    }
    module.hot.accept("./Identifier.vue?vue&type=template&id=30435314&", function () {
      api.rerender('30435314', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/Identifier.vue"
export default component.exports